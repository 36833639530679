<template>
  <v-container
    v-if="offers.length || loadingTable"
    fluid
    class="promo__container"
  >
    <v-row
      justify="space-between"
      align="center"
    >
      <v-col
        cols="auto"
        class="title-m-bold neutral-900--text"
      >
        Бонусные акции
      </v-col>
      <v-col
        cols="auto"
        class="col__input d-flex pa-0"
      >
        <base-text-field
          v-model.trim="search"
          class="field-search"
          :prepend-inner-icon="'$iconify_search-outlined'"
          placeholder="Быстрый поиск"
          style="min-width: 225px"
          hide-details
          clear-icon="$iconify_ion-close-circle-outline"
          :prepend-inner-icon-color="this.$vuetify.theme.themes.light['neutral-500']"
          clearable
        />
        <v-btn
          class="btn-create"
          color="primary"
          @click="routeStepper"
        >
          <v-icon
            class="icon"
            color="neutral-100"
            size="21"
          >
            $iconify_feather-plus-circle
          </v-icon>
          <p class="body-m-semibold neutral-100--text mb-0">
            Создать
          </p>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <promotions-table
          :loading="loadingTable"
          :search="search"
        />
      </v-col>
    </v-row>
  </v-container>
  <!-- Заглушка -->
  <base-empty-block-page
    v-else
    title="Создайте акцию!"
    description="Придумывайте дополнительные условия начисления бонусов. Это вовлекает клиентов в программу лояльности и повышает средний чек."
    action
  >
    <template v-slot:image>
      <v-img
        src="@/assets/png/speaker.png"
        width="178px"
      />
    </template>
    <template v-slot:actions>
      <v-btn
        class="empty-block__btn-action"
        color="primary"
        @click="routeStepper"
      >
        <v-icon
          class="icon"
          color="neutral-100"
          size="21"
        >
          $iconify_ion-megaphone-outline
        </v-icon>
        <p class="body-m-semibold neutral-100--text mb-0">
          Создать акцию
        </p>
      </v-btn>
    </template>
  </base-empty-block-page>
</template>

<script>
  import PromotionsTable from './Table.vue'

  export default {
    components: {
      PromotionsTable,
    },
    data () {
      return {
        search: '',
        loadingTable: false,
      }
    },
    computed: {
      offers () {
        return this.$store.getters['offers/offers/offers']
      },
      program () {
        return this.$store.getters['company/program/program']
      },
    },
    watch: {
      'program.id' (v) {
        if (v) {
          this.fetchData()
        }
      },
    },
    async mounted () {
      await this.fetchData()
    },
    methods: {
      async fetchData () {
        try {
          this.loadingTable = true
          await this.$store.dispatch('offers/offers/list')
        } catch (error) {
          this.loadingTable = false
        } finally {
          this.loadingTable = false
        }
      },
      routeStepper () {
        this.$router.push({ name: 'PromoMaster' })
      },
    },
  }
</script>

<style lang="scss" scoped>
.empty-block__btn-action {
  display: block;
  margin-right: auto;
  margin-left: auto;
  height: 53px!important;
  .icon {
    margin-right: 8px;
  }
}

.promo__container {
  .col__input {
    .field-search {
      width: 449px;
    }
    .btn-create {
      margin-left: 20px;
      .icon {
        margin-right: 8px;
      }
    }
  }
}
</style>
