<template>
  <base-table
    class="offers-table"
    :headers="table.headers"
    :items="offers"
    :search="search"
    :loading="loading"
    :options.sync="table.options"
    :storage-key="table.storageKey"
    :word-operations="table.wordOperations"
  >
    <template v-slot:[`item.id`]="{ item }">
      {{ item.id }}
    </template>
    <template v-slot:[`item.name`]="{ item }">
      {{ item.name }}
    </template>
    <template v-slot:[`item.offer_price_percent`]="{ item }">
      <span v-if="item.offer_price_percent !== null"> 
        <b>{{  Number(item.offer_price_percent) + "%" }}</b>
      {{ " " + priceDiscountModeToDecoding(item.price_discount_mode) + (item.price_discount_multiple_value ? " " + item.price_discount_multiple_value + ' ед.': "") }}
      </span><span v-else>-</span>
     </template>
    <template v-slot:[`item.created_at`]="{ item }">
      <date-column :value="item.created_at" />
    </template>
    <template v-slot:[`item.start_at`]="{ item }">
      <date-column :value="item.start_at" />
    </template>
    <template v-slot:[`item.finish_at`]="{ item }">
      <date-column :value="item.finish_at" />
    </template>
    <!--<template v-slot:[`item.filter_segment`]="{ item }">
      <segment-chip
          v-if="item.filter_segment"
          :name="item.filter_segment.name"
          :color="item.filter_segment.color"
          :is_public="item.filter_segment.is_public"
          class="ma-1"
          chip
      />     
    </template>-->
    <template v-slot:[`item.active`]="{ item }">
      <v-switch
        v-model="item.active"
        class="item__switch-active"
        :loading="item.loadingChangeActive"
        :disabled="item.loadingChangeActive"
        inset
        hide-details
        @click.stop=""
        @change="changeActive(item, $event)"
      />
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn
              icon
              small
              :loading="item.action"
              v-on="on"
          >
            <v-icon>$iconify_feather-more-vertical</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
              v-for="menuItem in getItemActions(item)"
              :key="menuItem.id"
              @click="menuItem.action(item)"
          >
            <v-list-item-icon>
              <v-icon color="neutral-500">
                {{ menuItem.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
                :class="{
                        'body-s-medium': true,
                        'neutral-500--text': true,
                      }"
            >
              {{ menuItem.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </base-table>
</template>

<script>
  import Template from "@/views/communications/mixins/conversation-list-view/template";
  import SegmentChip from '@/components/segment/SegmentChip'

  export default {
    components: {
      Template,
      SegmentChip,
       DateColumn: () => import('@/components/colums/DateColumn.vue'),
    },
    props: {
      search: {
        type: String,
        default: '',
      },
      loading: Boolean,
    },
    data () {
      return {
        loadingsDelete: {},
        table: {
          storageKey: 'OffersTable',
          options: {
            page: 1,
            itemsPerPage: 25,
          },
          headers: [
            { text: 'ID', align: 'start', value: 'id' },
            { text: 'Название', align: 'start', value: 'name' },
            { text: 'Приоритет', align: 'start', value: 'priority' },           
            //{ text: 'Сегмент\nклиентов', align: 'start', value: 'filter_segment', class: 'pre-header'},
            { text: 'Скидка', align: 'start', value: 'offer_price_percent'},
            { text: 'Создано', align: 'start', value: 'created_at' },
            { text: 'Старт', align: 'start', value: 'start_at' },
            { text: 'Окончание', align: 'start', value: 'finish_at' },            
            { text: '', align: 'end', value: 'active' },
            { text: 'Действия', align: 'end', value: 'action', width: '100px' },
          ],
          wordOperations: ['акция', 'акции', 'акций'],
        },
      }
    },
    computed: {
      offers () {
        return this.$store.getters['offers/offers/offers']
      },
    },
    watch: {},
    mounted () {
    },
    methods: {
      async changeActive (offer, active) {
        await this.$store.dispatch('offers/offers/changeActive', { offerId: offer.id, active })
        if (!offer.active) {
          this.$notify({
            title: 'Акция',
            text: 'Акция выключена',
            type: 'success',
          })
        } else {
          this.$notify({
            title: 'Акция',
            text: 'Акции включена',
            type: 'success',
          })
        }
      },
      priceDiscountModeToDecoding (value) {
        const discountMode = {
          all : "на все кол-во",
          one_quantity : "на 1 шт. с мин. ценой",
          multiple : "на каждую"
        }
        return discountMode[value]
      },
      editOffer (offer) {
        this.$router.push({ name: 'PromoEdit', params: { id: offer.id } })
      },
      copyOffer (offer) {
        this.$router.push({
          name: 'PromoMaster',
          query: {
            copyId: offer.id,
          },
        })
      },
      async deleteOffer (offer) {
        this.loadingsDelete[offer.id] = true
        await this.$store.dispatch('offers/offers/delete', offer.id)
        this.loadingsDelete[offer.id] = false
        delete this.loadingsDelete[offer.id]
        this.$notify({
          title: 'Акция',
          text: 'Акция удалена',
          type: 'success',
        })
      },
      getItemActions (item) {
        return [
          {
            id: 1,
            icon: '$iconify_feather-edit',
            title: 'Редактирование',
            action: this.editOffer,
            show: true,
          },
          {
            id: 2,
            icon: '$iconify_feather-copy',
            title: 'Копирование',
            action: this.copyOffer,
            show: true
          },
          {
            id: 3,
            icon: '$iconify_feather-trash',
            title: 'Удалить',
            action: this.deleteOffer,
            show: true
          }
        ].filter((x) => x.show)
      },
    },
  }
</script>

<style lang="scss" scoped>
@import '@/styles/vuetify-preset-plus/light_theme/_variables.sass';

.offers-table {
  .item__switch-active {
    display: inline-flex;
  }
  .item__action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 40px;
    .action-btn-edit {
      margin-right: 12px;
    }
    .action-btn-edit,
    .action-btn-copy,
    .action-btn-delete {
      height: 35px;
      width: 35px;
      border-radius: 50%;
      min-width: 0;
      .icon {
        color: $neutral-600;
      }
    }
  }
}
</style>
