var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-table',{staticClass:"offers-table",attrs:{"headers":_vm.table.headers,"items":_vm.offers,"search":_vm.search,"loading":_vm.loading,"options":_vm.table.options,"storage-key":_vm.table.storageKey,"word-operations":_vm.table.wordOperations},on:{"update:options":function($event){return _vm.$set(_vm.table, "options", $event)}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.offer_price_percent",fn:function(ref){
var item = ref.item;
return [(item.offer_price_percent !== null)?_c('span',[_c('b',[_vm._v(_vm._s(Number(item.offer_price_percent) + "%"))]),_vm._v(" "+_vm._s(" " + _vm.priceDiscountModeToDecoding(item.price_discount_mode) + (item.price_discount_multiple_value ? " " + item.price_discount_multiple_value + ' ед.': ""))+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('date-column',{attrs:{"value":item.created_at}})]}},{key:"item.start_at",fn:function(ref){
var item = ref.item;
return [_c('date-column',{attrs:{"value":item.start_at}})]}},{key:"item.finish_at",fn:function(ref){
var item = ref.item;
return [_c('date-column',{attrs:{"value":item.finish_at}})]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"item__switch-active",attrs:{"loading":item.loadingChangeActive,"disabled":item.loadingChangeActive,"inset":"","hide-details":""},on:{"click":function($event){$event.stopPropagation();},"change":function($event){return _vm.changeActive(item, $event)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","loading":item.action}},on),[_c('v-icon',[_vm._v("$iconify_feather-more-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.getItemActions(item)),function(menuItem){return _c('v-list-item',{key:menuItem.id,on:{"click":function($event){return menuItem.action(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"neutral-500"}},[_vm._v(" "+_vm._s(menuItem.icon)+" ")])],1),_c('v-list-item-title',{class:{
                      'body-s-medium': true,
                      'neutral-500--text': true,
                    }},[_vm._v(" "+_vm._s(menuItem.title)+" ")])],1)}),1)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }